<template>
    <div v-if="maiTaxList !== null" class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl " style="width: 800px; overflow: auto; max-height: 85%;">
      <div class="relative bg-screen_bg">
        <div>
          <div class="bg-primary flex justify-between p-3 mb-4">
            <div class=" font-medium text-white heading-3">
              Tax Break Down
            </div>
          </div>
          <div v-for="(tax, i) in finalTaxList" :key="i" class="px-4 mb-4">
            <div @click="setVisibleTax(tax.taxId)" class="card cursor-pointer text-text2 p-4 rounded-xl bg-white">
                <p class=" flex justify-between">
                    <span class=" font-bold">{{tax.taxName}}</span> 
                    <span class="font-bold">{{tax.ammount.toFixed(2) | amountOnlyNumber }}</span>
                </p>
                <p class="heading-7">{{tax.taxValue.toFixed(3)}}%</p>
                <div v-if="tax.visible" class="">
                  <div class="divider"></div>
                    <div v-for="(item, ind) in tax.list" :key="ind" :class="`${ind < tax.list.length - 1 ? 'border-b' : '' } py-2 px-2`">
                            <p class=" flex justify-between">
                                <span>{{item.itemName}}</span> 
                                <span>{{item.taxPrice.toFixed(2) | amountOnlyNumber}}</span>
                            </p>
                    <div class="heading-7 flex">
                        <p class=" w-44">{{item.itemPrice.toFixed(2)}}</p>
                        <span>Qty:{{item.qunty}}</span>
                    </div>
                    </div>
                </div>
            </div>
          </div>

        </div>
          <div class=" sticky bottom-0 bg-screen_bg w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Close'" @buttonAction="closeDetail()"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
export default {
  name: 'sendEmail',
  components: {
    Button,
  },
  data () {
    return {
      maiTaxList: [],
      finalTaxList: [],
      taxesAmmount: 0,
      visibleTaxId: 0
    }
  },
  props: ['itemList', 'vissibleTaxId'],
   created() {
  },
  mounted () {
    this.visibleTaxId = this.vissibleTaxId
    console.log("itemList", this.itemList);
    this.maiTaxList = this.itemList
    if (this.itemList.length > 0) {
        this.calculateTaxes(this.itemList)
    }
    document.body.style = 'overflow: hidden;'
  },
  watch: {},
  methods: {
    closeDetail () {
        document.body.style = 'overflow: visible;'
      this.$root.$emit('closeBreackDownHandler', false)
    },
    setVisibleTax (taxId) {
      let fIndex = this.finalTaxList.findIndex(x => x.taxId === taxId)
      this.finalTaxList[fIndex].visible = !this.finalTaxList[fIndex].visible
    },
    calculateTaxes (itemList) {
        let list = itemList
        list.map((data) => {
            let itemAmount = data.itemAmount
            data.taxRateList.map((rate) => {
                let itemTaxamount = itemAmount * rate.taxValue / 100
                let fIndex = this.finalTaxList.findIndex(x => x.taxId === rate.taxId)
                if (fIndex >= 0) {
                    this.finalTaxList[fIndex].ammount += itemTaxamount
                    this.finalTaxList[fIndex].list.push({itemName:data.itemName, taxPrice: itemTaxamount, itemPrice: data.itemPrice, qunty: data.itemQty  })
                } else {
                    let obj = {
                        visible: false,
                        taxName: rate.taxName,
                        taxValue: rate.taxValue,
                        ammount: itemTaxamount,
                        taxId: rate.taxId,
                        list: [{itemName:data.itemName, taxPrice: itemTaxamount, itemPrice: data.itemPrice, qunty: data.itemQty  }]
                    }
                    this.finalTaxList.push(obj)
                }
            })
            
        })
        setTimeout(() => {
            this.setVisibleTax(this.visibleTaxId)
        }, 500);
        this.finalTaxList.map(data=> {
            this.taxesAmmount += data.ammount
        })
    }
  },
  beforeDestroy () {
    // this.$root.$off('closeProfileDialog')
  }
}
</script>
<style scoped>

</style>