<template>
    <div v-if="itemInfo !== null" class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;">
      <div class="relative">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
              Item Detail
            </div>
          </div>
          <div class="p-4">
            <p class="text-text2 heading-2 font-bold">{{itemInfo.itemName}}</p>
            <p class="text-gray4 mb-4">{{itemInfo.itemCode}}</p>
            <div class="flex text-gray3 mb-4 px-4 justify-between">
              <div>
                <p>Item Qty</p>
                <p class="text-text2">{{itemInfo.itemQty}}</p>
              </div>
              <div>
                <p>Item Price</p>
                <p class="text-text2">{{itemInfo.itemPrice | amountOnlyNumber}}</p>
              </div>
              <div>
                <p>Item Total (A)</p>
                <p class="text-text2 text-right">{{itemInfo.itemAmount | amountOnlyNumber}}</p>
              </div>
            </div>
            <div class="divider my-2"></div>
             <div>
                <p class="text-text2 font-bold">Item Taxable:</p>
                <div>
                  <div v-for="(taxes, index) in finalTaxList" :key="index" class="flex border-b py-2 heading-6">
                        <div class="w-full px-4">
                            <div class="flex justify-between">
                                <div class="text-gray4">{{taxes.taxName.substr(0, 20)}} <span v-if="taxes.taxName.length > 20">...</span>
                                <p class="text-gray4">({{taxes.taxValue}} %)</p>
                                </div>
                                
                                <div>
                                  <p class="text-gray4 text-right">{{taxes.ammount.toFixed(2) | amountOnlyNumber}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div  class="flex justify-between px-4 border-b py-2 heading-6">
                        <div class="text-gray4">Total Tax (B)</div>
                            <div>
                              <p class="text-gray4 text-right">{{taxesAmmount.toFixed(2) | amountOnlyNumber}}</p>
                            </div>
                    </div>
                </div>
              </div>
              <div class="flex justify-between pt-2 px-4">
                  <p class="text-text2 font-bold w-full text-right">Total (A + B):</p>
                  <p class="text-text2 font-bold w-full text-right">{{(taxesAmmount + itemInfo.itemAmount).toFixed(2) | amountOnlyNumber}}</p>
              </div>
              <div>
                <p class="text-text1 font-bold">Item Description:</p>
                <p v-if="itemInfo.itemDescription !== ''" class="text-gray4">{{itemInfo.itemDescription}}</p>
                <p v-else class="text-gray4">-</p>
              </div>
          </div>

        </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Close'" @buttonAction="closeDetail()"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
export default {
  name: 'sendEmail',
  components: {
    Button,
  },
  data () {
    return {
      itemInfo: null,
      finalTaxList: [],
      taxesAmmount: 0
    }
  },
  props: ['itemDetail'],
   created() {
  },
  mounted () {
    this.itemInfo = this.itemDetail
    if (this.itemDetail !== null) {
      this.calculateTaxes(this.itemDetail.taxRateList, this.itemDetail.itemAmount)
    }
    document.body.style = 'overflow: hidden;'
  },
  watch: {},
  methods: {
    calculateTaxes (list, itemAmount) {

       list.map((rate) => {
          let itemTaxamount = itemAmount * rate.taxValue / 100
          let fIndex = this.finalTaxList.findIndex(x => x.taxId === rate.taxId)
          if (fIndex >= 0) {
              this.finalTaxList[fIndex].ammount += itemTaxamount
          } else {
              let obj = {
                  taxName: rate.taxName,
                  taxValue: rate.taxValue,
                  ammount: itemTaxamount,
                  taxId: rate.taxId
              }
              this.finalTaxList.push(obj)
          }
      })
      this.finalTaxList.map(data=> {
            this.taxesAmmount += data.ammount
        })
    },
    closeDetail () {
        document.body.style = 'overflow: visible;'
      this.$root.$emit('closeItemDetailHandler', false)
    },
  },
  beforeDestroy () {
    // this.$root.$off('closeProfileDialog')
  }
}
</script>
<style scoped>

</style>